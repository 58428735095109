
export default `
mutation (
  $orderId: String!
  $payData:String!
  $payStatus: String!
){
  paymentRetry(
      input: {    
        orderId: $orderId
        paymentSuccess: $payStatus
        payResponse: $payData
      }
  ) {
    code
    status
    burstTime
    availableRetryAttempt
    message
  }
}
`;
