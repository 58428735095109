
import { readonly, ref, useContext, useRouter } from '@nuxtjs/composition-api';
import { useApi } from "~/composables/useApi";
import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';
import getOrderDetailsGQL from "../../customQueries/getOrderDetails.gql";
import onOffCartOnOrderGQL from "../../customQueries/onOffCartOnOrder.gql";
import paymentRetryGQL from "../../customQueries/paymentRetry.gql";
import saveOrderDetailsGQL from "../../customQueries/saveOrderDetails.gql";
import setGdprConsentGQL from "../../customQueries/setGdprConsent.gql";
import setPayMethodOnRetryGQL from "../../customQueries/setPayMethodOnRetry.gql";





/**
 * Common function for order details
 */

export function useOrderDetailMethods(): any {
  const context = useContext();
  const router = useRouter();
  const { setCart, clear, cart } = useCart();
  const loading = ref(false);
  const { mutate, query } = useApi();

  //----------Get Payment Retry Details------------------
  const getPaymentRetryDetails = async (iOrderId: String) => {
    let result = null;
    let returnData = {
      paymentRetryValid: false,
      burstTime: 0
    };
    Logger.debug('useOrderDetailMethods.getPaymentRetryDetails');
    try {
      loading.value = true;
      const { data, errors } = await mutate(
        paymentRetryGQL,
        {
          orderId: iOrderId,
          payStatus: "",
          payData: ""
        }
      );
      if (data) {
        let res = <any>data;
        if (
          res.paymentRetry?.code.toString() === "200" &&
          res.paymentRetry?.availableRetryAttempt > 0 &&
          res.paymentRetry?.burstTime > 0
        ) {
          returnData = {
            paymentRetryValid: true,
            burstTime: res.paymentRetry.burstTime
          }
        }
      }
      result = { data: returnData, errors };
    } catch (err) {
      Logger.error(`useOrderDetailMethods.getPaymentRetryDetails`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useOrderDetailMethods [Result]:', { result });
    return result;
  };


  //----------Set Payment Retry  Details------------------
  const setPaymentRetryDetails = async (iOrderId: String, iPayData: String, iPayStatus: String) => {
    let result = null;
    let returnData = {
      paymentRetryValid: false,
      burstTime: 0
    };
    Logger.debug('useOrderDetailMethods.getPaymentRetryDetails');
    try {
      loading.value = true;
      const { data, errors } = await mutate(
        paymentRetryGQL,
        {
          orderId: iOrderId,
          payStatus: iPayStatus,
          payData: iPayData
        }
      );
      if (data) {
        let res: any = data;
        if (
          res.paymentRetry?.code.toString() === "200" &&
          res.paymentRetry?.availableRetryAttempt > 0 &&
          res.paymentRetry?.burstTime > 0
        ) {
          returnData = {
            paymentRetryValid: true,
            burstTime: res.paymentRetry.burstTime
          }
        }
      }
      result = { data: returnData, errors };
    } catch (err) {
      Logger.error(`useOrderDetailMethods.getPaymentRetryDetails`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useOrderDetailMethods [Result]:', { result });
    return result;
  };


  //----------Get Order Details------------------
  const getOrderDetails = async (iOrderId: String) => {
    let result = null;

    Logger.debug('useOrderDetailMethods.getOrderDetails');
    try {
      loading.value = true;
      const { data, errors } = await query(getOrderDetailsGQL, {
        orderId: iOrderId,
      });

      result = { data, errors };
    } catch (err) {
      Logger.error(`useOrderDetailMethods.getOrderDetails`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useOrderDetailMethods [Result]:', { result });
    return result;
  };


  //----------Save Payment deatils on order in Magento------------------
  const saveOrderAndPaymentDetails = async (iOrderId: String, iPayData: String, iPayStatus: String, iPayMethod: String) => {
    let result = null;
    // iPayMethod = iPayMethod === "scheme" ? "adyen_cc" : "adyen_hpp";
    let paymentDataObj = {
      orderId: iOrderId,
      payData: iPayData,
      payStatus: iPayStatus,
    };
    Logger.debug('useOrderDetailMethods.saveOrderAndPaymentDetails');
    try {
      loading.value = true;
      const { data, errors } = await mutate(
        saveOrderDetailsGQL,
        paymentDataObj
      );

      result = { data, errors };
    } catch (err) {
      Logger.error(`useOrderDetailMethods.saveOrderAndPaymentDetails`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useOrderDetailMethods [Result]:', { result });
    await ssrLogger({ OrderId: iOrderId, API: "saveOrderAndPaymentDetails", result });
    return result;
  };

  //----------Activate Cart on order in Magento------------------
  const activateCartOnOrder = async (iOrderId: String) => {
    let result = null;
    let dataObj = {
      orderId: iOrderId,
      cartStatus: "activate"
    };
    Logger.debug('useOrderDetailMethods.activateCartOnOrder');
    try {
      loading.value = true;
      const { data, errors } = await mutate(
        onOffCartOnOrderGQL,
        dataObj
      );

      result = { data, errors };
    } catch (err) {
      Logger.error(`useOrderDetailMethods.activateCartOnOrder`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useOrderDetailMethods.activateCartOnOrder [Result]:', { result });
    return result;
  };

  //----------Deactivate Cart on order in Magento------------------
  const deactivateCartOnOrder = async (iOrderId: String) => {
    let result = null;
    let dataObj = {
      orderId: iOrderId,
      cartStatus: "deactivate"
    };
    Logger.debug('useOrderDetailMethods.deactivateCartOnOrder');
    try {
      loading.value = true;
      const { data, errors } = await mutate(
        onOffCartOnOrderGQL,
        dataObj
      );

      result = { data, errors };
    } catch (err) {
      Logger.error(`useOrderDetailMethods.deactivateCartOnOrder`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useOrderDetailMethods.deactivateCartOnOrder [Result]:', { result });
    return result;
  };


  //----------- init enpty cart----------------

  const initEmptyCart = async () => {
    Logger.debug('useOrderDetailMethods.initEmptyCart');
    try {
      loading.value = true;
      context.app.$vsf.$magento.config.state.removeCartId();
      //re-init epmty cart
      setCart({
        id: "",
        is_virtual: false,
        total_quantity: 0,
        shipping_addresses: [],
      });
      //await clear({});
    } catch (err) {
      Logger.error('useOrderDetailMethods/initEmptyCart', err);
    } finally {
      loading.value = false;
    }
  }

  //----------------Go To order summary page------------------

  const goToOrderSummary = async (iOrderId: string) => {
    Logger.debug('useOrderDetailMethods.goToOrderSummary');
    try {
      loading.value = true;

      const orderSummaryRoute = context.app.localeRoute({
        name: "order-summary",
        params: { orderId: iOrderId },
      });

      await router.push(orderSummaryRoute);
    } catch (err) {
      Logger.error('useOrderDetailMethods/goToOrderSummary', err);
    } finally {
      loading.value = false;
    }
  };


  //--------------Save Adyen Payment Details on order----------------
  const saveAdyenPaymentDetailsOnOrder = async (iOrderId: string, iPayRes: any, iPayMethod: string) => {
    Logger.debug('useOrderDetailMethods.saveAdyenPaymentDetailsOnOrder');

    try {
      localStorage.removeItem("vfs-checkout_session");
      localStorage.removeItem("adyen-checkout__session");
      localStorage.removeItem("PaymentMeta");
      let iStatus = "";
      if (iPayRes.resultCode.toUpperCase() === "AUTHORISED") {
        //remove payment session
        iStatus = "success";
      } else {
        iStatus = iPayRes.resultCode;
      }

      const { data, errors } = await saveOrderAndPaymentDetails(
        iOrderId.toString(),
        JSON.stringify({
          adyen: iPayRes,
        }),
        iStatus,
        iPayMethod
      );


    } catch (err) {
      Logger.error(`useOrderDetailMethods.saveAdyenPaymentDetailsOnOrder`, err);
    } finally {
      loading.value = false;
    }
  };

  //--------------Save Adyen Payment Retry Details on order----------------
  const saveAdyenPaymentRetryDetailsOnOrder = async (iOrderId: string, iPayRes: any, iPayMethod: string) => {
    Logger.debug('useOrderDetailMethods.saveAdyenPaymentRetryDetailsOnOrder');

    try {
      localStorage.removeItem("vfs-checkout_session");
      localStorage.removeItem("adyen-checkout__session");
      localStorage.removeItem("PaymentMeta");
      let iStatus = "";
      if (iPayRes.resultCode.toUpperCase() === "AUTHORISED") {
        iStatus = "success";
      } else {
        iStatus = iPayRes.resultCode;
      }

      //Save payment status for order
      const { data, errors } = await saveOrderAndPaymentDetails(
        iOrderId.toString(),
        JSON.stringify({
          adyen: iPayRes,
        }),
        iStatus,
        iPayMethod
      );

      //Save payment-retry attempt details for order
      const { data: payRetryData, errors: payRetryError } =
        await setPaymentRetryDetails(
          iOrderId.toString(),
          JSON.stringify({
            adyen: iPayRes,
          }),
          iStatus
        );

    } catch (err) {
      Logger.error(`useOrderDetailMethods.saveAdyenPaymentRetryDetailsOnOrder`, err);
    } finally {
      loading.value = false;
    }
  };



  //----------------SSR Logger------------------

  const ssrLogger = async (iData) => {
    Logger.debug('useOrderDetailMethods.ssrLogger');
    try {
      loading.value = true;
      const res = (
        await context.app.$hudapi.post("adyen/ssrlogger", {
          payload: iData ? JSON.stringify(iData) : "",
        })
      ).data;

    } catch (err) {
      Logger.error('useOrderDetailMethods/ssrLogger', err);
    } finally {
      loading.value = false;
    }
    return;
  };

  //----------Save GDPR consent of Guest user on Cart in Magento------------------
  const saveGdprConsentOnCart = async (iConsentValue: boolean) => {
    let result = null;
    let dataObj = {
      cartId: cart.value.id,
      optOut: !iConsentValue
    };
    Logger.debug('useOrderDetailMethods.saveGdprConsentOnCart');
    try {
      loading.value = true;
      const { data, errors } = await mutate(
        setGdprConsentGQL,
        dataObj
      );

      result = { data, errors };
    } catch (err) {
      Logger.error(`useOrderDetailMethods.saveGdprConsentOnCart`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useOrderDetailMethods.saveGdprConsentOnCart [Result]:', { result });
    return result;
  };

  //----------Set Payment method on Retry in Magento [HS-77]------------------
  const setPayMethodOnRetry = async (iOrderId: string, iPayProvider: String) => {
    let result = null;
    let dataObj = {};
    if (iPayProvider === 'scheme') {
      dataObj = {
        orderId: iOrderId,
        payMethod: "adyen_cc",
        brandCode: ""
      };
    } else {
      dataObj = {
        orderId: iOrderId,
        payMethod: "adyen_hpp",
        brandCode: iPayProvider
      };
    }
    Logger.debug('useOrderDetailMethods.setPayMethodOnRetry');
    try {
      loading.value = true;
      const { data, errors } = await mutate(
        setPayMethodOnRetryGQL,
        dataObj
      );

      result = { data, errors };
    } catch (err) {
      Logger.error(`useOrderDetailMethods.setPayMethodOnRetry`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useOrderDetailMethods.setPayMethodOnRetry [Result]:', { result });
    return result;
  };


  return {
    loading: readonly(loading),
    getPaymentRetryDetails,
    getOrderDetails,
    saveOrderAndPaymentDetails,
    setPaymentRetryDetails,
    activateCartOnOrder,
    deactivateCartOnOrder,
    initEmptyCart,
    goToOrderSummary,
    saveAdyenPaymentDetailsOnOrder,
    saveAdyenPaymentRetryDetailsOnOrder,
    ssrLogger,
    saveGdprConsentOnCart,
    setPayMethodOnRetry
  };
}

export default useOrderDetailMethods;