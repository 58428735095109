
export default `
mutation (
  $cartId: String!
  $optOut:Boolean!
  
){
  setGuestGpdrOptOutsOnCart(
      input: {    
        cart_id: $cartId,
        gdpr_thirdhparts_opt_out: $optOut,
        msdyn_gdpr_opt_out: $optOut
      }
  ) {
    cart {
      gdpr_thirdhparts_opt_out
	    msdyn_gdpr_opt_out
    }
  }
}
`;
