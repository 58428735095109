
export default `
mutation (
  $orderId: String!
  $cartStatus: String!
){
  cartReset(
    input: {
      order_id: $orderId
      cart_status: $cartStatus
    }
  ) {
    status
    cart_id
  }
}
`;
