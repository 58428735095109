
import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import axios from "axios";


/**
 * Common Utility function 
 */

export function useUtility(): any {
  const context = useContext();
  const loading = ref(false);

  //----------Get User Ip------------------
  const getUserIp = async () => {
    let result = null;

    Logger.debug('useUtility.getUserIp');
    try {
      loading.value = true;
      let res = <any>await axios.get("https://jsonip.com/");
      result = res?.data?.ip ?? "";
    } catch (err) {
      Logger.error(`useUtility.getUserIp`, err);
    } finally {
      loading.value = false;
    }
    Logger.debug('useUtility.getUserIp [Result]:', { result });
    return result;
  };

  return {
    loading: readonly(loading),
    getUserIp,
  };
}

export default useUtility;