
export default `
query (
    $orderId: String!
 ) {
    salesOrder (id: $orderId) {
      increment_id
      customer_name
      customer_email
      order_status
      grand_total
      is_guest_customer
      created_at
      shipping_method
      subtotal_incl_tax
      subtotal
      discount_amount
      shipping_amount
      tax_amount
      shipping_incl_tax
      total
      shipping_amount
      payment_method
      shipping_address
      billing_address
      shipping_amount
        items {
          title
          visible_in_frontend
          parent_sku
          sku
          price
          special_price
          original_price
          quantity
          thumbnail
          color
          url_key
          size
          row_total
          row_total_incl_tax
        }
    }   
 }
`;
