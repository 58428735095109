























































































































































import {
  SfHeading,
  SfTable,
  SfCheckbox,
  SfButton,
  SfDivider,
  SfPrice,
  SfProperty,
  SfLink,
  SfImage,
} from "@storefront-ui/vue";
import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useMeta,
} from "@nuxtjs/composition-api";

import { useImage, useUiNotification, useConfig } from "~/composables";
import getShippingMethodPrice from "~/helpers/checkout/getShippingMethodPrice";

import type {
  BundleCartItem,
  ConfigurableCartItem,
} from "~/modules/GraphQL/types";
import { useUser } from "~/modules/customer/composables/useUser";
import { useOrderDetailMethods } from "~/modules/hudson/composables/useOrderDetailMethods";
import { Logger } from "~/helpers/logger";
import { useUtility } from "~/modules/hudson/composables/useUtility";

export default defineComponent({
  name: "HudPaymentRetry",
  layout: "checkoutLayout",
  components: {
    SfHeading,
    SfTable,
    SfCheckbox,
    SfButton,
    SfDivider,
    SfPrice,
    SfProperty,
    SfLink,
    SfImage,

    HudPaymentRetryProvider: () =>
      import(
        "~/modules/hudson/components/checkout/paymentRetry/HudPaymentRetryProvider.vue"
      ),
    AdyenPaymentRetryInterface: () =>
      import(
        "~/modules/hudson/components/checkout/paymentRetry/AdyenPaymentRetryInterface.vue"
      ),
    BurstTimer: () =>
      import(
        "~/modules/hudson/components/checkout/paymentRetry/BurstTimer.vue"
      ),
    PayRetrySkeleton: () =>
      import(
        "~/modules/hudson/components/checkout/paymentRetry/PayRetrySkeleton.vue"
      ),
  },
  props: {
    orderId: { type: String, default: "nodata" },
  },
  head: {},
  setup(props, context) {
    const order = ref(null);
    const { send: sendNotification } = useUiNotification();
    const { app } = useContext();
    const router = useRouter();
    const terms = ref(false);
    const getAttributes = (product: ConfigurableCartItem) =>
      product.configurable_options || [];
    const getBundles = (product: BundleCartItem) =>
      product.bundle_options?.map((b) => b.values).flat() || [];
    const paymentProvider = ref(null);
    const { isAuthenticated } = useUser();
    const totals = ref(null);
    const { getPaymentRetryDetails, getOrderDetails } = useOrderDetailMethods();
    const isPaymentRetryValid = ref(false);
    const paymentRetryBurstTime = ref(0);
    const { getUserIp } = useUtility();
    const ipAddress = ref(null);
    const { config } = useConfig();
    onMounted(async () => {
      if (props.orderId === "nodata") {
        sendNotification({
          id: Symbol(""),
          message: "Payment retry not valid",
          type: "danger",
          icon: "error",
          persist: true,
        });
        //Redirection to home
        await router.push(app.localePath("/"));
      } else {
        loadOrder();
      }
    });

    const loadOrder = async () => {
      try {
        //1. check if payment retry valid.
        const { data: payRetryRes, errors: payRetryError } =
          await getPaymentRetryDetails(props.orderId.toString());

        if (payRetryRes) {
          if (payRetryRes.paymentRetryValid) {
            isPaymentRetryValid.value = payRetryRes.paymentRetryValid;
            paymentRetryBurstTime.value = payRetryRes.burstTime;
          } else {
            sendNotification({
              id: Symbol(""),
              message: "Payement retry not valid for this order",
              type: "danger",
              icon: "error",
              persist: true,
            });
            //Redirection to home
            await router.push(app.localePath("/")); //scy3
          }
        }
        // isPaymentRetryValid.value = true; //scy3

        //2. get order details

        if (isPaymentRetryValid.value) {
          const { data: response, errors } = await getOrderDetails(
            props.orderId.toString()
          );
          if (response) {
            const res: any = response;
            order.value = {
              orderId: res.salesOrder.increment_id,
              createdAt: res.salesOrder.created_at,
              customerName: res.salesOrder.customer_name,
              customerEmail: res.salesOrder.customer_email,
              amount: res.salesOrder.grand_total,
              products: res.salesOrder.items,
              shippingAddress: JSON.parse(res.salesOrder.shipping_address),
              billingAddress: JSON.parse(res.salesOrder.billing_address),
              shippingCharges: res.salesOrder.shipping_incl_tax,
              subtotal_incl_tax: res.salesOrder.subtotal_incl_tax,
              discount_amount: res.salesOrder.discount_amount,
            };
            totals.value = { grandTotal: order.value?.amount };

            if (ipAddress.value === null) {
              ipAddress.value = await getUserIp();
            }
          }
        }
      } catch (err) {
        Logger.error("Payment Retry");
      }
    };

    const { getMagentoImage, imageSizes } = useImage();

    const paymentProviderChange = async (iPaymenObj) => {
      paymentProvider.value = iPaymenObj;
    };

    const termsChanged = async (iValue) => {
      if (!iValue) {
        paymentProvider.value = null;
      }
    };
    const burstTimesUp = async (iValue) => {
      if (iValue) {
        sendNotification({
          id: Symbol(""),
          message: "Payment period has timed out. Please try again.",
          type: "danger",
          icon: "error",
          persist: true,
          title: "Checkout",
        });
        //Redirection to home
        await router.push(app.localePath("/"));
      }
    };
    const parseProductImg = (iValue) => {
      let imgArr = JSON.parse(iValue);
      if (imgArr && imgArr.length) {
        return imgArr[0];
      }
      return "";
    };
    useMeta({
      title: "Payment Retry | " + config.value.default_title,
      meta: [
        { charset: "utf8" },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          hid: "apple-mobile-web-app-title",
          name: "apple-mobile-web-app-title",
          content: "Payment Retry | " + config.value.default_title,
        },
        {
          hid: "og:title",
          name: "og:title",
          property: "og:title",
          content: "Payment Retry | " + config.value.default_title,
        },
        {
          hid: "og:site_name",
          name: "og:site_name",
          property: "og:site_name",
          content: "Payment Retry | " + config.value.default_title,
        },
      ],
    });
    return {
      getShippingMethodPrice,
      products: [],
      selectedShippingMethod: null,
      tableHeaders: ["Description", "Quantity", "Amount"],
      terms,
      totals, //computed(() => cartGetters.getTotals(cart.value)),
      getAttributes,
      getBundles,
      getMagentoImage,
      imageSizes,
      paymentProviderChange,
      paymentProvider,
      isAuthenticated,
      termsChanged,
      order,
      isPaymentRetryValid,
      paymentRetryBurstTime,
      burstTimesUp,
      parseProductImg,
      ipAddress,
    };
  },
});
