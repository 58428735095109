
export default `
mutation (
    $orderId: String!
    $payData: String!
    $payStatus:String!
 ) {
    orderPayment(
        input: {
          order_id: $orderId
          pay_response:$payData
          status: $payStatus
        }
      ) {
        status
      }
    
 }
`;
