
export default `
mutation (
  $orderId: String!
  $payMethod: String!
  $brandCode: String!
  
){
  setPaymentMethodOnRetry(
    input: {
      orderId: $orderId
      payMethod:$payMethod
      brandCode: $brandCode
    }
  ) {
    status
  }
}
`;
